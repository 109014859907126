<template>
  <v-container
    id="wizard"
    tag="section"
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <v-snackbar
      v-model="snackbar"
      multi-line
      :timeout="timeout"
      top
      color="primary"
    >
      {{ alertmessage }}
      <v-btn
        color="white"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="10"
      >
        <v-card
          style="border-radius: 20px;"
          elevation="0"
        >
          <v-card
            min-height="300"
            elevation="0"
          >
            <div
              class="text-center text-h2 font-weight-light ml-1 mr-1 mb-6 mt-4 blanketbutton--text"
            >
              <v-img
                src="/img/logo.svg"
                height="100"
                contain
              />
              Direct agent processing
              <v-row justify="center">
                <v-col
                  cols="12"
                  sm="8"
                  md="8"
                  lg="8"
                >
                  <v-divider
                    class="test text-center"
                  />
                </v-col>
              </v-row>
            </div>
            <div
              class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
            >
              <v-row justify="center ma-1">
                <v-col
                  cols="12"
                  sm="10"
                  md="10"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <div class="text-h3 ma-6 black--text">
                        Please be advised that your quote requires agent attention. An agent will be in touch with you shortly. Please provide your contact details below.
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="mobile"
                        label="Mobile Number"
                        :append-icon="mobilestatus ? 'mdi-check' : 'mdi-phone-alert'"
                        outlined
                        counter="10"
                        color="primary"
                        @change="validateMobile"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="email"
                        label="Email Address"
                        :append-icon="emailstatus ? 'mdi-check' : 'mdi-email-alert'"
                        outlined
                        color="primary"
                        @change="validateEmail"
                      />
                    </v-col>
                  </v-row>

                  <v-row class="ma-1">
                    <v-btn
                      text
                      to="/"
                    >
                      Cancel
                    </v-btn>

                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      :disabled="!contactstatus"
                      @click="submitquote"
                    >
                      Get me covered
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import moment from 'moment'
  const axios = require('axios')

  export default {

    // bday en Your birth date is:
    // height en What is your height?
    // weight in lbs en What is your weight in pounds?
    // nic en In the past 5 years have you used any form of tobacco or nicotine (including e-cigs, patches or gum)?
    name: 'DashboardFormsWizard',
    data: () => ({
      pickerDate: '',
      email: '',
      mobile: '',
      snackbar: false,
      timeout: 5000,
      alertmessage: '',
      currentuser: '',
      questions: [],
      c1: 1,
      e6: 1,
      f6: 1,
      safeid: '',
      sitename: '',
      group: '',
      max: 50,
      allowSpaces: true,
      unique: true,
      userterm: {
        email: '',
        firstname: '',
        lastname: '',
        mobile: '',
        postcode: '',
        income: '',
        gender: '',
        goodhealth: '',
        resident: '',
        firsttime: '',
        bdayyear: '',
        beneficiary: '',
        bday: '',
        feet: '',
        inches: '',
        height: {
          feet: 0,
          inches: 0
        },
        weight: '',
        nic: ''
      },
      beneficiarylist: ['Spouse', 'Child', 'Children of this marriage', 'Sibling', 'Nephew', 'Nice', 'Parent', 'Cousin', 'Synagogue', 'University', 'Bank', 'Ex-Wife', 'Church', 'Partner', 'Charity', 'Other'],
      activePicker: 'YEAR',
      date: null,
      menu: false,
      filterchars: true,
      onlyspace: false,
      phoneRules: [
        v => !!v || 'Zipcode is required',
        v => /^[0-9]*$/.test(v) || 'Numeric values only',
        v => (v && v.length <= 11) || 'Zipcode must be 5 digits',
      ],
      postcodeRules: [
        v => !!v || 'Zipcode is required',
        v => /^[0-9]*$/.test(v) || 'Numeric values only',
        v => (v && v.length <= 5) || 'Zipcode must be 5 digits',
      ],
      numeric_only: [
        v => !!v || 'This field is required',
        v => /^[0-9]*$/.test(v) || 'Numeric values only',
      ],
      sitenameRules: [
        v => !!v || 'Site name is required',
        v => (v && v.length <= 10) || 'Site name must be less than 10 characters',
      ],
    }),
    computed: {
      contactstatus () {
        let status = false
        if (this.$store.getters.getEmailStatus && this.$store.getters.getMobileStatus) {
          status = true
        }
        return status
      },
      emailstatus () {
        let status = false
        status = this.$store.getters.getEmailStatus
        return status
      },
      mobilestatus () {
        let status = false
        status = this.$store.getters.getMobileStatus
        console.log('valid mobile ', status)
        return status
      },
      validLocationTab () {
        let blockuser = true
        if (
          this.userterm.postcode &&
          this.userterm.resident &&
          this.userterm.income
        ) {
          blockuser = false
          // maybe they changed it so just update it again
          this.$store.dispatch('checkpostcode', this.userterm.postcode)
        }
        return blockuser
      },
      validHealthTab () {
        let blockuser = true
        if (
          this.userterm.feet &&
          this.userterm.weight &&
          this.userterm.bday &&
          this.userterm.gender
        ) {
          blockuser = false
        }
        return blockuser
      },
      rules () {
        const rules = []
        if (this.unique) {
          const rule =
            v => (!this.loadedsites.includes(v)) ||
              'Values must be unique and not be an existing site name'

          rules.push(rule)
        }
        if (!this.onlyspace) {
          const rule =
            v => v.trim().length || 'Name can not be only whitespace characters'

          rules.push(rule)
        }
        if (this.max) {
          const rule =
            v => (v || '').length <= this.max ||
              `A maximum of ${this.max} characters is allowed in a site name`

          rules.push(rule)
        }

        if (!this.allowSpaces) {
          const rule =
            v => (v || '').indexOf(' ') < 0 ||
              'No spaces are allowed'

          rules.push(rule)
        }
        if (this.filterchars) {
          const rule =
            v => !v.match(/\\t|[#\$\.]/gi) || ' $ # [ ] tabs are not allowed in a site name'
          rules.push(rule)
        }
        return rules
      },

    },
    watch: {
      mobilestatus (val) {
        console.log(val)
      },
      menu (val) {
        val && setTimeout(() => {
          // 年から選ぶようにする
          this.$refs.picker.activePicker = 'YEAR'
          // 選び始めたら初期化
          this.pickerDate = null
        })
      },
      sitename: 'validateField',
      max: 'validateField',
      model: 'validateField',
    },
    created () {
    },
    beforeDestroy () {
      // now we know their details so lets set the classification

    },
    methods: {
      validateEmail () {
        this.$store.dispatch('validateEmail', this.email)
      },
      validateMobile () {
        this.$store.dispatch('validateMobile', this.mobile)
      },
      tagquestion (value) {
        this.$gtag.event('InitialSurveyTermLife', { action: value })
      },
      submitquote () {
        let appdetails = this.$store.getters.getapplieddetails
        let directprovider = {
          provider: 'Direct Agent Processing',
          premium: '0',
          rank: 1
        }
        let requestdetails = {
          appdetails: appdetails,
          faceamount: 0,
          termlength: 'none',
          provider: directprovider
        }
        this.$store.dispatch('routepolicyrequest', requestdetails)
        this.$gtag.event('TermCoverageOptionsEnd')
        this.$router.push({ path: '/' })
      },
      getpostcodedata (postcode) {
        this.$store.dispatch('checkpostcode', postcode)
        this.e6 = '2'
        this.$gtag.event('InitialSurveyTermLife', { action: 'Postcode' })
      },
      getquestion (language, label) {
        let questiontext = 'unknown'
        if (!this.questions) {
          return
        }
        questiontext = this.questions.find(entry => {
          if (entry.label === label) {
            return true
          }
        })
        if (!questiontext) {
          return 'this I do not know'
        }
        return questiontext.question
      },
      nexttab (completedTab) {
        this.$gtag.event('InitialSurveyTermLife', { action: completedTab })
      },
      calcbmi () {
        let totalinches = (parseInt(this.userterm.feet * 12)) + parseInt(this.userterm.inches)
        let smoker = false
        if (this.userterm.smoker === 'yes') {
          smoker = true
        }

        let rec = {
          height: { feet: this.userterm.feet, inches: this.userterm.inches },
          weight: this.userterm.weight,
          smoker: smoker
        }
        this.$gtag.event('InitialSurveyTermLife', { action: 'weight' })
        // this.$store.dispatch('calcBMI', rec)
      },
      selectcoverage () {
        // calc BMI
        let totalinches = (parseInt(this.userterm.feet * 12)) + parseInt(this.userterm.inches)
        let rec = {
          height: totalinches,
          weight: this.userterm.weight
        }
        // incase they changed it in the form
        let postcode = this.userterm.postcode
        this.$store.dispatch('checkpostcode', postcode)

        this.$store.dispatch('recordOptions', this.userterm)
        this.$gtag.event('InitialSurveyTermLife', { action: 'surveyComplete' })
        this.$router.push({ path: '/TermCoverage' })
      },
      save (date) {
        this.$refs.menu.save(date)

        this.userterm.bday = date
        this.$gtag.event('InitialSurveyTermLife', { action: 'birthDay' })
      },
    }
  }
</script>

<style scoped>
.test {
    border-width: 1px !important;
    border-color:#0d9eb0 !important;
    height: 100%;
    justify-content: center;
    text-align: center;
}
.blanketheader {
    font-family: 'formadjrdeckregular';
    color: 'red';
    font-size:1px;
    justify-content: center;
    text-align: center;
}
.rounded-card{
    border-radius:50px;
    background: "red";
}
.button-sections {
    display: flex;
    text-align: center;
    margin: 20px 0 0 0;
    font-size:18px;
    justify-content: center;
}

.button-sections > .yes {
    border-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border: 1px solid #129fb1;
    padding: 12px 50px;
    margin: 1px;
    background: #129fb1;
    color: #fff;
}

.button-sections > .no {
    border-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #fff;
    color: #009db3;
    margin: 1px;
    border: 1px solid #129fb1;
    padding: 12px 50px;

}

.button-sections > .yes:hover {
    background: #005f6c;
    border: 1px solid #005f6c;
}

.button-sections > .no:hover {
    background: #eee;
}
</style>
<style lang="sass">
  .v-card.v-card.v-card--account
    border-color: currentColor
    border-width: 4px

    .v-icon
      color: inherit

  .v-card--account,
  .v-card--account:before
    border-radius: 50%

</style>
